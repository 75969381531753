<template>
  <v-container grid-list-xs>

    <!-- Sanckbar d'erreur -->
    <v-snackbar v-model="snackErreur" color="red">
      {{snackMessage}}
      <v-btn text color="black" @click.native="snackErreur = false">Close</v-btn>
    </v-snackbar>

    <!-- Popup ajout utilisateur -->
    <v-dialog v-model="dialogAjout" persistent :overlay="false" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-title primary-title>
          Ajout d'un utilisateur
        </v-card-title>
        <v-card-text>
          <v-text-field label="Identifiant" v-model="identifiant" autofocus></v-text-field>
          <v-text-field label="Courriel" v-model="courriel"></v-text-field>
          <v-text-field label="Mot de passe" v-model="motdepasse"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <hgroup>
            <v-btn color="success" @click="boutonValiderNouvelUtilisateurClick">Ok</v-btn>
            <v-btn text @click="dialogAjout = false">Annuler</v-btn>
          </hgroup>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <hgroup class="mb-3">
      <v-btn color="success" @click="boutonAjouterUtilisateurClick">Ajouter</v-btn>
      <v-btn color="success" class="ml-2" @click="copierUtilisateurs">Copier</v-btn>
      
    </hgroup>

    <v-simple-table dense fixed-header height="700px">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Id</th>
            <th class="text-center">Identifiant</th>
            <th class="text-center">Courriel</th>
            <th class="text-center">Vue containers</th>
            <th class="text-center">Admin containers</th>
            <th class="text-center">Vue P.O.</th>
            <th class="text-center">Suivi train</th>
            <th class="text-center">Suivi train manager</th>
            <th class="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="utilisateur in utilisateurs" :key="utilisateur.id">
            <td>{{ utilisateur.id }}</td>
            <td>{{ utilisateur.username }}</td>
            <td>{{ utilisateur.email }}</td>
            <td><droit-utilisateur :utilisateur="utilisateur" :roleId="$ROLE_CONTAINERVIEW" @rolemodifie="lire()"></droit-utilisateur></td>
            <td><droit-utilisateur :utilisateur="utilisateur" :roleId="$ROLE_CONTAINERADMIN" @rolemodifie="lire()"></droit-utilisateur></td>
            <td><droit-utilisateur :utilisateur="utilisateur" :roleId="$ROLE_POVIEW" @rolemodifie="lire()"></droit-utilisateur></td>
            <td><droit-utilisateur :utilisateur="utilisateur" :roleId="$ROLE_SUIVITRAINMANAGER" @rolemodifie="lire()"></droit-utilisateur></td>
            <td><droit-utilisateur :utilisateur="utilisateur" :roleId="$ROLE_SUIVITRAIN" @rolemodifie="lire()"></droit-utilisateur></td>
            <td>
              <hgroup>
                <v-btn color="error" fab x-small class="my-1" @click="boutonSupprimerUtilisateurClick(utilisateur)"><v-icon>mdi-delete</v-icon></v-btn>
              </hgroup>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

  </v-container>
</template>

<script>
import axios from 'axios';
import DroitUtilisateur from './DroitUtilisateur.vue';

export default {

  components: { DroitUtilisateur },

  created() {
    
  },

  mounted() {
    this.lire()
  },

  data() {
    return {
      loading: false,
      snackErreur: false,
      snackMessage: "",
      utilisateurs:[],
      dialogAjout: false,
      courriel: "",
      identifiant: "",
      motdepasse: ""
    }
  },

  methods: {

    lire() {
      this.utilisateurs = []
      const filter = {
        "include": "roles",
        "order": "id DESC"
      }
      axios.get("/api/wdUsers?filter=" + JSON.stringify(filter) + "&access_token=" + this.$user.id)
      .then(function(response) {
        this.utilisateurs = response.data
      }.bind(this))
      .catch(function(error) {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
        }
      }.bind(this));
    },

    afficherErreur(message) {
      this.snackMessage = message;
      this.snackErreur = true
      this.loading = false;
    },

    computeDroit(utilisateur, roleId) {
      const role = utilisateur.roles.find(r => r.roleId == roleId)
      return role !== undefined
    },

    boutonAjouterUtilisateurClick()  {
      this.identifiant = ""
      this.courriel = ""
      this.motdepasse = ""
      this.dialogAjout = true
    },

    boutonValiderNouvelUtilisateurClick()  {
      const body = {
        "realm": "-",
        "username": this.identifiant,
        "email": this.courriel,
        "emailVerified": true,
        "password": this.motdepasse
      }
      axios.post("/api/wdUsers?access_token=" + this.$user.id, body)
      .then(function() {
        this.dialogAjout = false
        this.lire()
      }.bind(this))
      .catch(function(error) {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
        }
      }.bind(this));
    },

    async boutonSupprimerUtilisateurClick(utilisateur)  {
      const ok = confirm("Supprimer utilisateur " + utilisateur.username + " ?")
      if (!ok) {
        return
      }
      try {
        await axios.delete("/api/wdUsers/" + utilisateur.id + "/roles?access_token=" + this.$user.id)
        await axios.delete("/api/wdUsers/" + utilisateur.id + "?access_token=" + this.$user.id)
        this.lire()
      }
      catch(error) {
        if ((error.response != undefined) && (error.response.status == 401)) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
        }
      }
    },

    copierUtilisateurs() {
      var tableau = "Id\tIdentifiant\tVue containers\tAdmin containers\tVue P.O.\tSuivi train\tSuivi train manager\r\n"
      for(var idxUtilisateur = 0; idxUtilisateur < this.utilisateurs.length; idxUtilisateur++) {
        const utilisateur = this.utilisateurs[idxUtilisateur]
        tableau += utilisateur.id + "\t" + utilisateur.username + "\t"
        tableau += this.computeDroit(utilisateur, this.$ROLE_CONTAINERVIEW) ? "oui\t" : "\t"
        tableau += this.computeDroit(utilisateur, this.$ROLE_CONTAINERADMIN) ? "oui\t" : "\t"
        tableau += this.computeDroit(utilisateur, this.$ROLE_POVIEW) ? "oui\t" : "\t"
        tableau += this.computeDroit(utilisateur, this.$ROLE_SUIVITRAINMANAGER) ? "oui\t" : "\t"
        tableau += this.computeDroit(utilisateur, this.$ROLE_SUIVITRAIN) ? "oui\r\n" : "\r\n"
      }
      navigator.clipboard.writeText(tableau);
    }
  }
}

</script>

<style scoped>

</style>