<template>
  <hgroup>
    <v-btn x-small v-if="oui" color="success" @click="boutonSupprimerRoleClick(false)">OUI</v-btn>
    <v-btn x-small v-if="!oui" color="#E0E0E0" @click="boutonAjouterRoleClick(true)">NON</v-btn>
  </hgroup>
</template>

<script>
import axios from 'axios';

export default {

  props: {
    utilisateur: {
      type: Object
    },
    roleId: {
      type: Number
    }
  },

  data() {
    return {
      oui: false,
      roleMappingId: -1
    }
  },

  created() {
    this.oui = this.computeDroit(this.utilisateur, this.roleId)
    console.log("droit", this.utilisateur, this.roleId, this.oui)
  },

  methods: {
    computeDroit(utilisateur, roleId) {
      const role = utilisateur.roles.find(r => r.roleId == roleId)
      if (role !== undefined) {
        this.roleMappingId = role.id
      }
      return role !== undefined
    },

    boutonAjouterRoleClick() {
      const ok = confirm("Ajouter le rôle à l'utilisateur " + this.utilisateur.username + " ?")
      if (!ok) {
        return
      }
      const body = {
        "principalType": "user",
        "principalId": this.utilisateur.id,
        "roleId": this.roleId
      }
      axios.post("/api/wdUsers/" + this.utilisateur.id + "/roles?access_token=" + this.$user.id, body)
      .then(function() {
        //this.oui = true
        this.$emit("rolemodifie")
      }.bind(this))
      .catch(function(error) {
        if (error.response.status == 401) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
        }
      }.bind(this));
    },

    boutonSupprimerRoleClick() {
      const ok2 = confirm("Supprimer le rôle à l'utilisateur " + this.utilisateur.username + " ?")
      if (!ok2) {
        return
      }
      axios.delete("/api/wdUsers/" + this.utilisateur.id + "/roles/" + this.roleMappingId + "?access_token=" + this.$user.id)
      .then(function() {
        //this.oui = false
        this.$emit("rolemodifie")
      }.bind(this))
      .catch(function(error) {
        if ((error.response != undefined) && (error.response.status == 401)) {
          // Erreur "accès refusé", redirection vers la page de connexion
          this.afficherErreur('Accès refusé')
          //this.$router.push('/login?to=/containers')
        }
        else {
          this.afficherErreur(error.message + " : " + error.response.statusText)
        }
      }.bind(this));
    }
  }

}
</script>

<style>

</style>